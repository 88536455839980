body[data-theme="dark"] {
  @import "../dark-slider.scss";

  .slider-tab {
    background-color: #8c8c8c;
  }

  .title-styles {
    color: white;
  }

  .header-icon {
    color: #3c342c;
    opacity: 0.8;
  }

  .slider-image {
    border: 5px solid #8c8c8c;
  }

  .language {
    background-color: #494949;
  }

  .link-href {
    color: white;
  }

  .project-date {
    background-color: #918e97;
    color: white;
  }

  header {
    background-color: #494949;
    h1 {
      color: white;
    }
  }

  #about {
    background-color: #7f7f7f;
    h1 span {
      color: white;
    }
    .polaroid span {
      background: #6d6d6d;
    }
    .card {
      background: #6d6d6d;
      color: white;
    }
  }

  #portfolio {
    background: #5b5b5b;
    .section-title {
      color: white !important;
    }
    .foto div {
      background: #6d6d6d;
    }
    .project-title-settings {
      color: white;
    }
  }

  #resume {
    filter: brightness(80%);
    background: #5b5b5b;
    .section-title {
      color: white !important;
    }

    .experience-badge {
      background: #919191 !important;
      color: white;
    }

    .main-badge {
      background: #919191 !important;
    }

    .vertical-timeline-element-date {
      color: white;
    }

    .vertical-timeline-element-icon {
      background: #919191 !important;
    }
    @media only screen and (max-width: 1169px) {
      .vertical-timeline-element-date {
        color: black;
      }
    }
  }

  .modal-inside .modal-content {
    background: #5b5b5b;
    color: white;
  }

  .close-icon {
    color: white;
  }

  #contact-form {
    background: #7f7f7f;

    body {
      background-color: #7f7f7f;
    }
    h1 span {
      font: 18px/24px "opensans-bold", sans-serif;
      text-transform: uppercase;
      letter-spacing: 3px;
      color: white;
      text-align: center;
    }
    form {
      max-width: 420px;
      margin: 5px auto;
    }
    ::placeholder {
      color: white;
      opacity: 1;
    }

    .feedback-input {
      color: white;
      font-family: Helvetica, Arial, sans-serif;
      font-weight: 500;
      font-size: 18px;
      border-radius: 5px;
      line-height: 22px;
      background-color: transparent;
      border: 2px solid #5b5b5b;
      transition: all 0.3s;
      padding: 13px;
      margin-bottom: 15px;
      width: 100%;
      box-sizing: border-box;
      outline: 0;
    }

    .feedback-input:focus {
      border: 2px solid #5b5b5b;
    }

    textarea {
      height: 150px;
      line-height: 150%;
      resize: vertical;
    }

    [type="submit"] {
      font-family: "Montserrat", Arial, Helvetica, sans-serif;
      width: 100%;
      background: #5b5b5b;
      border-radius: 5px;
      border: 0;
      cursor: pointer;
      color: white;
      font-size: 24px;
      padding-top: 10px;
      padding-bottom: 10px;
      transition: all 0.3s;
      margin-top: -4px;
      font-weight: 700;
    }
    [type="submit"]:hover {
      background: rgb(70, 69, 69);
    }
  }
}

// #about {
//   background-color: #7f7f7f;
//   h1 span {
//     color: white;
//   }
//   .polaroid span {
//     background: #6d6d6d;
//   }
//   .card {
//     background: #6d6d6d;
//     color: white;
//   }
// }
